<template>
  <section @contextmenu.prevent>
    <div class="ticket-container">
      <!-- Bundesliga Ticket -->
      <router-link to="/bundesliga" class="ticket-link">
        <p-card class="ticket-card">
          <div class="info-bar">
            <img id="logo" src="/img/bundesliga.png" alt="Bundesliga Logo" />
            <h2>{{ bundesligaData.liga }}</h2>
          </div>
          <div class="content-placeholder">
            <h3>{{ bundesligaData.heim }} vs. {{ bundesligaData.gegner }}</h3>
            <div class="block-info">
              <p>Block</p>
              <h2>{{ bundesligaData.block }}</h2>
            </div>
            <div class="kurve-info">
              <p>{{ bundesligaData.kurve }}</p>
              <p>Mitglied</p>
            </div>
            <p class="date-info">{{ bundesligaData.datum }}, {{ bundesligaData.uhr }} Uhr</p>
          </div>
        </p-card>
      </router-link>

      <!-- Europa League Ticket -->
      <router-link to="/europa-league" class="ticket-link">
        <p-card class="ticket-card">
          <div class="info-bar">
            <img id="logo" src="/img/europaleague.png" alt="Europa League Logo" />
            <h2>{{ europaLeagueData.liga }}</h2>
          </div>
          <div class="content-placeholder">
            <h3>{{ europaLeagueData.heim }} vs. {{ europaLeagueData.gegner }}</h3>
            <div class="block-info">
              <p>Block</p>
              <h2>{{ europaLeagueData.block }}</h2>
            </div>
            <div class="kurve-info">
              <p>{{ europaLeagueData.kurve }}</p>
              <p>Mitglied</p>
            </div>
            <p class="date-info">{{ europaLeagueData.datum }}, {{ europaLeagueData.uhr }} Uhr</p>
          </div>
        </p-card>
      </router-link>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from 'vue';
import Card from 'primevue/card';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();

const bundesligaData = reactive({
  liga: 'Bundesliga',
  heim: '',
  gegner: '',
  datum: '',
  uhr: '',
  block: '40',
  kurve: 'Unterrang Nordwestkurve',
});

const europaLeagueData = reactive({
  liga: 'Europa League',
  heim: '',
  gegner: '',
  datum: '',
  uhr: '',
  block: '41',
  kurve: 'Unterrang Südostkurve',
});

onBeforeMount(() => {
  // Daten für Bundesliga abrufen
  axios.get('/api/get-bundesliga-data')
    .then((response) => {
      const { data } = response;
      bundesligaData.heim = data.heim;
      bundesligaData.gegner = data.gegner;
      bundesligaData.datum = data.datum;
      bundesligaData.uhr = data.uhr;
    })
    .catch((err) => {
      console.error('Fehler beim Abrufen der Bundesliga-Daten:', err);
    });

  // Daten für Europa League abrufen
  axios.get('/api/get-europa-league-data')
    .then((response) => {
      const { data } = response;
      europaLeagueData.heim = data.heim;
      europaLeagueData.gegner = data.gegner;
      europaLeagueData.datum = data.datum;
      europaLeagueData.uhr = data.uhr;
    })
    .catch((err) => {
      console.error('Fehler beim Abrufen der Europa League-Daten:', err);
    });
});
</script>

<style lang="scss">
section {
  position: relative;
  height: 95vh;
  max-height: 100vh !important;
}

.ticket-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.ticket-link {
  width: 100%;
  text-decoration: none;
}

.ticket-card {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1f1f1f;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-bar {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #1f1f1f;
}

.info-bar img#logo {
  width: 50px;
  height: 50px;
  background-color: white;
  padding: 5px;
}

.info-bar h2 {
  font-size: 1rem;
  flex: 1;
  text-align: left;
  margin: 0 25px;
  color: #ffffff;
}

.content-placeholder {
  padding: 20px;
  text-align: left;
}

.content-placeholder h3,
.content-placeholder p,
.content-placeholder h2 {
  margin: 10px 0;
  line-height: 1.2;
}

.block-info {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 20px;
}

.kurve-info {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 20px;
}

.date-info {
  margin-top: 20px;
  margin-bottom: 40px;
}

/* Responsive Anpassungen */
@media (min-width: 600px) {
  .ticket-container {
    flex-direction: row;
    justify-content: space-around;
  }
  .ticket-link {
    width: 45%;
  }
}
</style>
