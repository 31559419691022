import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import EuropaLeagueView from '@/views/EuropaLeagueView.vue';
import SelectLeague from '@/views/SelectLeague.vue';
import BundesligaView from '@/views/BundesligaView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: SelectLeague,
  },
  {
    path: '/bundesliga',
    name: 'bundesliga',
    component: BundesligaView,
  },
  {
    path: '/europa-league',
    name: 'europa',
    component: EuropaLeagueView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
